(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/banner_layouts/card-puff-wide/assets/javascripts/render-component.js') >= 0) return;  svs.modules.push('/components/banner_layouts/card-puff-wide/assets/javascripts/render-component.js');

'use strict';

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const isServer = typeof exports === 'object';

async function renderComponent(model, widgetInfo, position, req, res) {
  var _res$locals, _svs$core, _model$contentType;
  let hbsCompiled;
  let format;
  let isAdmin;
  let formatImages;
  const trinidad = isServer && require('trinidad-core').core;
  const features = isServer ? (res === null || res === void 0 || (_res$locals = res.locals) === null || _res$locals === void 0 ? void 0 : _res$locals.features) || [] : ((_svs$core = svs.core) === null || _svs$core === void 0 || (_svs$core = _svs$core.data) === null || _svs$core === void 0 ? void 0 : _svs$core.features) || [];
  if (isServer) {
    const hbs = global.internalInstances.get('hbs');
    hbsCompiled = hbs.cache['components-banner_layouts-card_puff_wide-card_puff_wide'];
    format = trinidad.components.require('utils', 'format').api;
    isAdmin = req.userSession.hasRole(req.userSession.roles.INTERNAL);
    formatImages = trinidad.components.require('banner_common', 'banner-helpers').images.formatImages;
  } else {
    hbsCompiled = svs.banner_layouts.card_puff_wide.templates.card_puff_wide;
    format = svs.utils.format;
    isAdmin = svs.core.userSession.hasRole(svs.core.userSession.roles.INTERNAL);
    formatImages = svs.banner_common.banner_helpers.formatImages;
  }
  const bannerHelpers = isServer ? trinidad.components.require('banner_common', 'banner-helpers') : svs.banner_common.banner_helpers;
  const {
    getCallToAction,
    groupNumbers
  } = bannerHelpers;
  const {
    callToActions: modelCallToActions,
    id,
    layoutConfig,
    layoutType,
    targetOffer,
    title
  } = model;
  const trackingStringTitle = format.safeString(title);
  const contentType = ((_model$contentType = model.contentType) === null || _model$contentType === void 0 ? void 0 : _model$contentType.split(':').pop()) || 'banner';
  const formattedImages = await formatImages(model, 'typeName');
  const productImage = formattedImages === null || formattedImages === void 0 ? void 0 : formattedImages.productImage;
  const crm = targetOffer || '';
  const actionViewConfig = {
    block: true,
    focus: true,
    inverted: true
  };
  const callToActions = modelCallToActions.map(action => {
    const callToAction = getCallToAction(action, actionViewConfig);
    return callToAction;
  });
  const noLabel = layoutConfig.product === 'ingen';
  const trackingString = "".concat(widgetInfo.widgetType, "/").concat(widgetInfo.trackingId, "/").concat(position, "/").concat(layoutType, "/").concat(trackingStringTitle);
  const heading = groupNumbers(layoutConfig.header);
  const content = groupNumbers(layoutConfig.content);
  return hbsCompiled(_objectSpread(_objectSpread({}, layoutConfig), {}, {
    features,
    heading,
    content,
    bannerId: id,
    callToActions,
    contentType,
    crm,
    isAdmin,
    noLabel,
    productImage,
    trackingString
  })) || '';
}
if (isServer) {
  module.exports = {
    renderComponent
  };
} else {
  svs.banner_layouts.card_puff_wide = {
    renderComponent
  };
}

 })(window);