(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/banner_layouts/card-puff-wide/views/card-puff-wide.js') >= 0) return;  svs.modules.push('/components/banner_layouts/card-puff-wide/views/card-puff-wide.js');
(function(svs, Handlebars) {
_svs=window.svs=window.svs||{};
_svs.banner_layouts=_svs.banner_layouts||{};
_svs.banner_layouts.card_puff_wide=_svs.banner_layouts.card_puff_wide||{};
_svs.banner_layouts.card_puff_wide.templates=_svs.banner_layouts.card_puff_wide.templates||{};
svs.banner_layouts.card_puff_wide.templates.card_puff_wide = Handlebars.template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"banner-card-puff-wide-item banner-card-puff-wide bg-white f-content\"\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isAdmin") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":4},"end":{"line":6,"column":11}}})) != null ? stack1 : "")
    + "    data-impression=true data-impression-tracking=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"trackingString") || (depth0 != null ? lookupProperty(depth0,"trackingString") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"trackingString","hash":{},"data":data,"loc":{"start":{"line":7,"column":51},"end":{"line":7,"column":69}}}) : helper)))
    + "\">\n    <div class=\"banner-card-puff-wide-top-image\">\n"
    + ((stack1 = lookupProperty(helpers,"with").call(alias1,(depth0 != null ? lookupProperty(depth0,"productImage") : depth0),{"name":"with","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":8},"end":{"line":11,"column":17}}})) != null ? stack1 : "")
    + "    </div>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"noLabel") : depth0),{"name":"unless","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":4},"end":{"line":38,"column":11}}})) != null ? stack1 : "")
    + "<div class=\"banner-card-body padding-md-2 padding-xs-1\">\n    <div class=\"banner-card-heading margin-bottom-half f-center\">\n        <span class=\"block f-bold fc-navy margin-0 banner-card-lineheight-compact\">"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"heading") || (depth0 != null ? lookupProperty(depth0,"heading") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"heading","hash":{},"data":data,"loc":{"start":{"line":41,"column":83},"end":{"line":41,"column":96}}}) : helper))) != null ? stack1 : "")
    + "</span>\n    </div>\n    <div class=\"banner-card-text margin-bottom-half f-center\">\n        <span class=\"block fc-navy margin-0\">"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"content") || (depth0 != null ? lookupProperty(depth0,"content") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"content","hash":{},"data":data,"loc":{"start":{"line":44,"column":45},"end":{"line":44,"column":58}}}) : helper))) != null ? stack1 : "")
    + "</span>\n    </div>\n</div>\n</div>\n<div class=\"banner-card-bottom-container\">\n    <div class=\"padding-md-left-2 padding-md-right-2 padding-xs-left-1 padding-xs-right-1 u-flex u-flex-justify-content-center\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"callToActions") : depth0),{"name":"each","hash":{},"fn":container.program(17, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":50,"column":8},"end":{"line":54,"column":17}}})) != null ? stack1 : "")
    + "    </div>\n</div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        data-cmsid=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"bannerId") || (depth0 != null ? lookupProperty(depth0,"bannerId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"bannerId","hash":{},"data":data,"loc":{"start":{"line":4,"column":20},"end":{"line":4,"column":32}}}) : helper)))
    + "\" \n        data-cmsType=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"contentType") || (depth0 != null ? lookupProperty(depth0,"contentType") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"contentType","hash":{},"data":data,"loc":{"start":{"line":5,"column":22},"end":{"line":5,"column":37}}}) : helper)))
    + "\" \n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"components-content-image_v2-image"),depth0,{"name":"components-content-image_v2-image","hash":{"legacy":true,"image":(depth0 != null ? lookupProperty(depth0,"productImage") : depth0)},"data":data,"indent":"           ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div\n        class=\"banner-card-label u-flex u-flex-align-items-center u-flex-justify-content-center u-text-center "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(lookupProperty(helpers,"getSquareMetaData")||(depth0 && lookupProperty(depth0,"getSquareMetaData"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"product") : depth0),"skrap",{"name":"getSquareMetaData","hash":{},"data":data,"loc":{"start":{"line":15,"column":116},"end":{"line":15,"column":151}}}),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":110},"end":{"line":15,"column":174}}})) != null ? stack1 : "")
    + " "
    + alias3((lookupProperty(helpers,"getSquareMetaData")||(depth0 && lookupProperty(depth0,"getSquareMetaData"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"product") : depth0),"headerBg",{"name":"getSquareMetaData","hash":{},"data":data,"loc":{"start":{"line":15,"column":175},"end":{"line":15,"column":215}}}))
    + " "
    + alias3((lookupProperty(helpers,"getSquareMetaData")||(depth0 && lookupProperty(depth0,"getSquareMetaData"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"product") : depth0),"labelWidth",{"name":"getSquareMetaData","hash":{},"data":data,"loc":{"start":{"line":15,"column":216},"end":{"line":15,"column":258}}}))
    + "\">\n        <div class=\"label-bg "
    + alias3((lookupProperty(helpers,"getSquareMetaData")||(depth0 && lookupProperty(depth0,"getSquareMetaData"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"product") : depth0),"headerBg",{"name":"getSquareMetaData","hash":{},"data":data,"loc":{"start":{"line":16,"column":29},"end":{"line":16,"column":69}}}))
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(lookupProperty(helpers,"getSquareMetaData")||(depth0 && lookupProperty(depth0,"getSquareMetaData"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"product") : depth0),"skrap",{"name":"getSquareMetaData","hash":{},"data":data,"loc":{"start":{"line":17,"column":18},"end":{"line":17,"column":53}}}),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.program(15, data, 0),"data":data,"loc":{"start":{"line":17,"column":12},"end":{"line":36,"column":11}}})) != null ? stack1 : "")
    + "</div>\n";
},"7":function(container,depth0,helpers,partials,data) {
    return "skrap-product";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"brands-torned-wrapper\">\n"
    + ((stack1 = (lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"brands-torned/brands_torned",{"name":"ui","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":16},"end":{"line":29,"column":23}}})) != null ? stack1 : "")
    + "            </div>\n        </div>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||alias2).call(alias1,"brands-torned/brands_torned_item",{"name":"ui","hash":{"class":"fc-white align-right\n                padding-xs-right f-900 u-flex u-flex-justify-content-end u-flex-align-items-center","bgColor":"skrapspel-secondary"},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":16},"end":{"line":23,"column":23}}})) != null ? stack1 : "")
    + ((stack1 = (lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||alias2).call(alias1,"brands-torned/brands_torned_item",{"name":"ui","hash":{"class":"fc-red padding-xs-left f-900 u-text-left u-flex u-flex-justify-content-start\n                u-flex-align-items-center","isTorned":true,"bgColor":(lookupProperty(helpers,"getSquareMetaData")||(depth0 && lookupProperty(depth0,"getSquareMetaData"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"product") : depth0),"headerBg",{"name":"getSquareMetaData","hash":{},"data":data,"loc":{"start":{"line":24,"column":65},"end":{"line":24,"column":103}}})},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":24,"column":16},"end":{"line":28,"column":23}}})) != null ? stack1 : "");
},"11":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                "
    + container.escapeExpression((lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"brands",{"name":"ui","hash":{"emblem":true,"brand":"skrap-neg"},"data":data,"loc":{"start":{"line":22,"column":16},"end":{"line":22,"column":61}}}))
    + "\n";
},"13":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                "
    + container.escapeExpression((lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||alias2).call(alias1,"brands",{"name":"ui","hash":{"brand":(lookupProperty(helpers,"sizedSquareBrandName")||(depth0 && lookupProperty(depth0,"sizedSquareBrandName"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"product") : depth0),"100",{"name":"sizedSquareBrandName","hash":{},"data":data,"loc":{"start":{"line":27,"column":36},"end":{"line":27,"column":72}}})},"data":data,"loc":{"start":{"line":27,"column":16},"end":{"line":27,"column":74}}}))
    + "\n";
},"15":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    </div>\n    "
    + container.escapeExpression((lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||alias2).call(alias1,"brands",{"name":"ui","hash":{"outline":(lookupProperty(helpers,"getSquareMetaData")||(depth0 && lookupProperty(depth0,"getSquareMetaData"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"product") : depth0),"emblemOutline",{"name":"getSquareMetaData","hash":{},"data":data,"loc":{"start":{"line":34,"column":92},"end":{"line":35,"column":20}}}),"emblem":true,"size":"150","brand":(lookupProperty(helpers,"sizedSquareBrandName")||(depth0 && lookupProperty(depth0,"sizedSquareBrandName"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"product") : depth0),"100",{"name":"sizedSquareBrandName","hash":{},"data":data,"loc":{"start":{"line":34,"column":24},"end":{"line":34,"column":60}}})},"data":data,"loc":{"start":{"line":34,"column":4},"end":{"line":35,"column":22}}}))
    + "\n";
},"17":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"btn-block\">\n              "
    + container.escapeExpression((lookupProperty(helpers,"dynamicActionTemplate")||(depth0 && lookupProperty(depth0,"dynamicActionTemplate"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"actionType") : depth0),(depth0 != null ? lookupProperty(depth0,"actionConfig") : depth0),(depths[1] != null ? lookupProperty(depths[1],"trackingString") : depths[1]),{"name":"dynamicActionTemplate","hash":{},"data":data,"loc":{"start":{"line":52,"column":14},"end":{"line":52,"column":81}}}))
    + "\n            </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"paper",{"name":"ui","hash":{"class":"inline-block banner-edit-paper banner-card-paper clip paper-link"},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":57,"column":7}}})) != null ? stack1 : "");
},"usePartial":true,"useData":true,"useDepths":true});
Handlebars.partials['components-banner_layouts-card_puff_wide-card_puff_wide'] = svs.banner_layouts.card_puff_wide.templates.card_puff_wide;
})(svs, Handlebars);


 })(window);